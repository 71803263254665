import React, { useEffect, useState } from 'react';

import { Container, Segment } from 'semantic-ui-react';

const Footer = () => {
  const [footerHTML, setFooterHTML] = useState('');
  useEffect(() => {
    let savedFooterHTML = localStorage.getItem('footer_html');
    if (!savedFooterHTML) savedFooterHTML = '';
    setFooterHTML(savedFooterHTML);
  });

  return (
    <Segment vertical>
      <Container textAlign="center">
        {footerHTML === '' ? (
          <div className="custom-footer">
            Copyright © 2024 GoAI Group. All Rights Reserved. GoAI团队 版权所有
          </div>
        ) : (
          <div
            className="custom-footer"
            dangerouslySetInnerHTML={{ __html: footerHTML }}
          ></div>
        )}
      </Container>
    </Segment>
  );
};

export default Footer;
