import React, { useState } from 'react';
import { Button, Modal, Segment, Tab } from 'semantic-ui-react';
import SystemSetting from '../../components/SystemSetting';
import { Link } from 'react-router-dom';
import { API, copy, isRoot, showError, showSuccess } from '../../helpers';
import { marked } from 'marked';
import WeChatSetting from '../../components/WeChatSetting';

const Setting = () => {

  const generateToken = async () => {
    const res = await API.get('/api/user/token');
    const { success, message, data } = res.data;
    if (success) {
      await copy(data);
      showSuccess(`令牌已重置并已复制到剪切板：${data}`);
    } else {
      showError(message);
    }
  };

  let panes = [
    {
      menuItem: '个人设置',
      render: () => (
        <Tab.Pane attached={true}>
          <Button as={Link} to={`/user/edit/`}>
            更新个人信息
          </Button>
          <Button onClick={generateToken}>生成访问令牌</Button>
        </Tab.Pane>
      ),
    },
  ];

  if (isRoot()) {
    panes.push({
      menuItem: '系统设置',
      render: () => (
        <Tab.Pane attached={false}>
          <SystemSetting />
        </Tab.Pane>
      ),
    });
    panes.push({
      menuItem: '微信设置',
      render: () => (
        <Tab.Pane attached={false}>
          <WeChatSetting />
        </Tab.Pane>
      ),
    });
  }

  return (
    <Segment>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Segment>
  );
};

export default Setting;
